// DiarrowCMS Bootstrap variables
@import "variables";
// Original Bootstrap bundle without "variables.scss"
@import "bootstrap";
@import "glyphicon-spin";
@import "table";
@import "helpers";

// Custom Bootstrap extensions
.navbar {
  border: none;

  .glyphicon {
    margin-right: 5px;
  }
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .navbar-header {
    padding: 3px;
  }
}

.container-wide {
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.editable-content {
  background-color: #ffffff;
  padding: 15px;
}

.btn-header {
  margin-top: 30px;
}

.alert {
  margin-bottom: 0px;
}

.media-thumbnail {
  max-width: 400px;
  height: 208px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: block;
}

.flag {
  background-position: center center;
  width: 16px;
  height: 11px;
  display: inline-block;
}

.flag-no {
  background-image: url("/vendor/cms/img/no.gif");
}

// Embed responsive
@import "embed";

// ContentBuilder
@import "../vendor/contentbuilder/contentbuilder";

// Bootstrap DateTime picker
@import "../../../../node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build";

// SweetAlert
@import "../../../../node_modules/sweetalert/dev/sweetalert";

// Nestable
@import "../vendor/nestable/nestable";